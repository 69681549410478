@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "/assets/shop/styles/main.scss";
@import '~glightbox/dist/css/glightbox.min.css';

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600&family=Quicksand:wght@400;600&display=swap');

@import "./partials/fonts.scss";
@import "./partials/all.scss";
@import "./partials/header.scss";
@import "./partials/footer.scss";
@import "./partials/buttons.scss";
@import "./homepage/homepage.scss";
@import "./products/products.scss";
@import "./account/account.scss";



.dropdown-toggle {
  .badge {
    transform: translateY(-10%);
  }
}

.layout-split {
  @media all and (min-width: map-get($grid-breakpoints, 'md')) {
    display: flex;

    > * {
      flex: 1 0 50%;
    }
  }
}

#sylius-product-adding-to-cart {
    .mb-3 {
        margin-bottom: 0 !important;
    }
}

#sylius-cart-items {
    margin-bottom: 0;
}

.sylius-quantity {
    width: 0;
    input {
        width: 80px;
    }
}

.btn {
    .btn-icon {
        margin-right: 4px;
    }
    svg {
        width: 20px;
        height: 20px;
    }
}

.text-right {
    text-align: right;
}

.cart-table-remove-cell {
    width: 0;
}


.card {
    > .table-responsive {
        > .table {
            > * {
                tr:last-child {
                    td {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}

label.required {
    &:after {
        content: " *";
        color: $danger;
    }
}

h5 {
    > .form-check {
        label {
            margin-top: 3px;
        }
    }
}

.align-items-stretch {
    > .col {
        > .card {
            min-height: 100%;

            > .card-body {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        }
    }
}

#sylius-product-variants {
    td.text-right {
        width: 0;
    }
    .form-check {
        label {
            &:after {
                display: none;
            }
        }
    }
}

.mollie-payments {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 0;
    margin: 14px -8px 0;

    .online-payment__item {
        flex: 0 0 50%;
        padding: 8px;

        position: relative;
        input {
            opacity: 0;
            position: absolute;

            &:checked + label {
                border: 4px solid $primary;
                padding: 10px;
            }
        }
        label {
            padding: 13px;
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            img {
                height: 40px;
                width: auto;
            }
        }

        @media all and (min-width: map-get($grid-breakpoints, 'md')) {
            flex: 0 0 33.33333%;
        }

        @media all and (min-width: map-get($grid-breakpoints, 'lg')) {
            flex: 0 0 20%;
        }
    }
}


.sortable.sorted {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
        width: 16px;
        height: 16px;
    }
}

.address-book-select .dropdown-item {
    cursor: pointer;
}

.account-order-pay {
    .order-payment-step {
        h1 {
            display: none;
        }
    }
}

.complete-card {
    max-width: map-get($grid-breakpoints, 'lg');
    margin: 0 auto;
}

.alert-heading {
    svg {
        transform: translateY(-2px);
        margin-right: 2px;
    }
}
