a{
    &.btn{
        text-decoration: none !important;
        border: $primary;
    }
}

#nav-security {
    a {
        &.btn {
            font-size: 14px;
        }
    }

}

#sylius-cart-clear {
    background: white;
    color: $primary;
    border: 1px solid $primary !important;

    &:hover {
        background: $primary;
        color: white;
    }
}
