@import "./_variables.scss";
// Style du thème parent
@import "/themes/BootstrapTheme/assets/style/shop.scss";

/** GLOBAL CLASSES **/
html {
    height: 100%;
}
body {
    background-color: $background-color;
    height: auto;
    min-height: 100vh;
     display: flex;
    flex-direction: column;
}

p {
    margin-bottom: 0;
}

body a {
    text-decoration: none;
}

a p {
    color: $text-color;
}
.container-70{
    width: 75%;
    max-width: 1090px;
}

.alert-info {
    --bs-alert-bg:#5b5c4399;
    --bs-alert-color:#ffffff;
    --bs-alert-border-color:#5b5c4391;
    --bs-alert-link-color:#ffffff;
}

@import "./layout/_btn_toggle.scss";
@import "./layout/header.scss";
@import "./layout/banner.scss";
@import "./layout/footer.scss";

.img-show {
    width: 65%;
}

.enhanced-checkbox {
    height: 18px;
    width: 18px;
  }

  .enhanced-label {
    font-weight: bold;
  }

  .disabled {
    opacity: 0.5 !important;
    pointer-events: none;
  }

  .heartbeat {
    animation: heartbeat 1.5s infinite;
  }
  .container-succursale-var {
    background-image: url("../media/images/carces.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    border: solid 1px #B4AF9D;
    padding: 1.5rem 4rem;
    margin: 1.5rem 0;
    text-align: center;
    position: relative;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.container-succursale-var::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3); /* Noir semi-transparent pour l'effet d'obscurcissement */
    z-index: 0;
}

.container-succursale-var * {
    position: relative;
    z-index: 1;
}
.infos-succursale-var {
    display : flex;
    justify-content : space-around;
}

.btn-primary {
    background-color : #99CC33!important;
}

.btn-primary:hover {
    background-color : #a6dd36!important;
}

.pagination-mobile {
    display: none!important;
}

.search-bar{
    display:flex!important;
    height:2.5rem;


 }

.btns-search-bar .bi {
    color:#8A8A8A;

}

.search-zone .form-control {
    height:2.5rem;
    border :none;
    background-color: #efefef;

 }
 .search-button {
    display: block;
    width: 100%;
    padding: 0.375rem 0.45rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    height:2.5rem;
    color: #000;
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: none;
    border-radius: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

 }
 .btns-search-bar{
    display:flex;
 }
.btns-search-bar button,.btns-search-bar a{
   background-color: #efefef;
 }


.bi-x::before {
font-size:28px;
font-weight:bolder!important;
}
.bi-search::before {
font-weight:bold!important;
font-size:16px;
}

.tab-files i{
    font-size: 2rem;
    }
  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }
/* --------@ MEDIA query 792PX -------- */

@media (max-width: 792px) {

    #container-categorie, #container-product, #container-infos {
        grid-row-start: auto;
        grid-row-end: auto;
        grid-column-start: auto;
        grid-column-end: auto;
        margin: auto;
    }

    .pagination-mobile {
        display: flex!important;
    }

    .container-70{
            width: 100%;
        }



.img-show {
    width: 100%;
}
}
