
footer {
    width: 100%;
    min-height: 180px;
    bottom: 0;
    background-color: #b4af9d;
    text-align: center;
    margin-top: 20px;
    padding: 20px;
    color:$text-color;

}

footer .footer{

    display: flex;
    flex-direction: column;
}


footer .footer-coordonnes p{

    font-weight: 400;
    color:$text-color;
    margin-bottom: 20px;

}

footer .footer-mention-legale a{

    text-decoration: none;
    color:$text-color!important;
    font-weight: 500;

}
footer .footer-mention-legale a:hover{

    text-shadow: 2px 2px 4px rgb(255, 255, 255);

}
footer .footer-mention-legale p{

    font-weight: 300;
    font-size: 0.9rem;
    max-width: 70vw;
    margin: auto;
}
.instagram {
    position: relative;
    margin: 0 auto;
    width: 50px; /* ajusté à 100px */
    height: 50px; /* ajusté à 100px */
    align-self: center;
    border-radius: 12px; /* ajusté proportionnellement */
    background: radial-gradient(circle at 30% 110%, #ffdb8b 0%, #ee653d 25%, #d42e81 50%, #a237b6 75%, #3e5fbc 100%);
    box-shadow: 0 6px 16px 0.4px rgba(0,0,0,0.3); /* ajusté proportionnellement */
    transition: all 0.3s ease-out;
    span {
        display: block;
        border: 3px solid #fff; /* ajusté proportionnellement */
        width: 30px; /* ajusté proportionnellement */
        height: 30px; /* ajusté proportionnellement */
        border-radius: 8px; /* ajusté proportionnellement */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &:before, &:after {
            content: "";
            position: absolute;
        }
        &:before {
            width: 12px; /* ajusté proportionnellement */
            height: 12px; /* ajusté proportionnellement */
            border-radius: 50%;
            border: 3px solid #fff; /* ajusté proportionnellement */
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &:after {
            width: 4px; /* ajusté proportionnellement */
            height: 4px; /* ajusté proportionnellement */
            border-radius: 50%;
            background: #fff;
            top: 3px; /* ajusté proportionnellement */
            right: 3px; /* ajusté proportionnellement */
        }
    }
}

.instagram:hover {
    background: radial-gradient(circle at 30% 110%, #ffe8a6 0%, #f07c59 25%, #de5c96 50%, #b55bd0 75%, #5f7cc9 100%); /* Couleurs éclaircies pour l'effet de survol */
}

/* --------@ MEDIA query 990PX -------- */
@media (max-width: 990px){
footer {
margin: 30px 0 0 0;
}
}
