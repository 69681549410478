#container-product {
    width: 75%;
    max-width: 1190px;
}

// #container-infos {
//     grid-row-start: 4;
//     grid-row-end: 5;
//     grid-column-start: 2;
//     grid-column-end: 6;
// }

.title-container-product  {
   margin: 0.5rem;
}
.title-container-product h2 {
    background-color: #5b5c43;
    padding: 15px 25px;
    color: #FFF;
    margin-bottom: 0.5rem ;
    font-size: 1.3rem;
}

.grid-container-product {

    height:88%;
    margin: 0.5rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;
    transition: none !important;

}
.grid-container-latest {
    margin: 0.5rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    transition: none !important;
}
.card-product-grid {
    height: 300px;
    padding: 8px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 0.10rem;
    box-shadow: 3px 9px 30px -2px rgba(0, 0, 0, 0.18);
    border-bottom: solid 10px #5B5C43;

}
.footer-card-product{
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.card-product-grid img {
    width: 120px;
    margin: auto;
}

.card-product-grid h4 {
    font-size: 1.1rem;

}
.card-product-grid .voir-fiche-slug {
    text-decoration: none;
    background-color: #e3e4d4;
    border-radius: 32px;
    width: 6rem;
    height: 1.5rem;
    color: #5b5c43;
    font-weight:700;
    font-size:0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 0 0 auto;

}

.card-product-grid p {

    color: #5b5c43;
    font-weight:bold;
    font-size: 1.1rem;

}
.card-product-grid span {

    font-size: 10px;

}
.list-container-product {
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    transition: none !important;


}
// ! ajout css pour list / grid

.card-product-ref, .card-product-description {
    display: none;
}

.card-product-list {
    height: 300px;
    padding: 8px;
    text-align:start;
    height : auto;
    width : 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;


}
.card-product-list img {

    margin-left: 0;
    margin: auto;

}
.card-product-list .footer-card-product {
    display: flex;
    flex-direction: column;

    justify-content: space-around;
}

.card-product-list .card-product-description {
    text-decoration: none;
    color: #5b5c43;
    font-weight:500;
    font-size: 0.8rem;
}
.card-product-list .card-product-ref {
    text-decoration: none;
    color: #5b5c43;
    font-weight:bold;
    font-size: 0.9rem;
    margin-top: 1rem;
}

.class-list-product1 {
    order: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0.5rem 1rem;
    width: 100%;


}

.class-list-product2 {
    order: 1;
    display: flex;
    align-items: center;
    margin: 0.5rem 0.5rem
}

.class-list-product3 {
    order:3;
    margin: 0.5rem 0.5rem
}

#container-list-grid {
    transition: none!important;
}

.original-price-card {
    font-weight:normal!important;
    text-decoration:line-through #5B5C43!important;

}

.card-product-grid span {
    font-size: 10px;
}
.card-product-grid::first-letter {
    text-transform: uppercase!important;
}
#price-ttc {
    position: absolute;
    margin-left: 5px;
    font-size: 10px;

}
.capitalize-first-letter::first-letter {
    text-transform: uppercase;
}

.wrap-container-product {
    margin: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: auto;
}



.card-product2 p {
    color: #5b5c43;
    font-weight: 500;
    font-size: 1.1rem;
}
.original-price-card {
    font-weight: normal !important;
    text-decoration: line-through #5B5C43 !important;

}
.container-car-product {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0.5rem 0.5rem 1.3rem;
    flex: 1 1 230px;
    max-width: 230px;
    min-width: 230px;
}

.card-product2{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 4px;
    overflow: hidden;
    background-color:#f3f3f3;
    width: 100%;
    max-height: 370px;
}

.card-img-grid2 {
    display: flex;
    justify-content: center;
    height: 40%;
    overflow: hidden;
    padding-top: 6px;
}

.card-img-grid2 img {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    height : 100%;
}


.card-infos2 {
    padding: 10px 20px;
    flex-grow: 1; /* Permet au contenu de pousser le bouton en bas */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.card-product2-list .card-infos2 {
    padding: 0 3.5rem;
}

.price-ttc {
    position: absolute;
    margin-left: 5px;
    font-size: 12px;
}

.card-product-title {
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: 500;
    color: #888570;
    text-transform: lowercase; /* Transforme tout le texte en minuscules */
}

.card-product-title::first-letter {
    text-transform: uppercase; /* Transforme la première lettre en majuscule */
}

.card-product-ref2{
    text-align: right;
    color:#9EAC96;
    font-weight:300!important;
}

.card-product-pricing {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.voir-fiche-slug2 {
    text-decoration: none;
    color: white;
    background-color:#B4AF9D;
    text-align: center;
    display: block;
    padding: 10px 0;
}

.card-product-title:hover {

    color : #5B5C43;
    transition : all 0.3s ease-in;

}
.voir-fiche-slug2:hover {

    background-color:#86856F;
    transition : all 0.3s ease-in;

}
.card-img-grid2:hover img {
    filter: brightness(1.1);
    transition : all 0.3s ease-in;
}



.list-container-product {
    display: flex;
    flex-direction: column;
    }

    .container-car-product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0.5rem auto;
    flex: 1 1 230px;
    width: 100%;
    }

    .card-product2-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 4px;
    overflow: hidden;
    background-color: #f3f3f3;
    width: 100%;
    height: 200px;
    align-items: center;
    padding: 0 3rem;

}
.card-img-list2 {
    display: flex;
    justify-content: center;
    height: 80%;
    margin-left: 1rem;
    align-items: center;
    padding: 0.5rem 0;
    width: 180px;
}
.card-img-list2 img{
    max-width: 235px;
    height: auto;
    object-fit: contain;
}

.card-product-description2, .card-product-pricing-none  {
    display:none;
}
.card-product-description-visible {
    display: block !important;
    font-size: 0.8rem;
    max-height: 95px;
     overflow: hidden;

}

.card-product-pricing2 {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}
.product-list-right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height : 100%;
    min-width: 130px;
    text-align: center;
}
.product-list-right a{
    padding: 10px 8px;
}
.search-product-home {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media (max-width: 1500px) {
    .grid-container-latest{
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 1190px) {
    .grid-container-product{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 962px) {
    .grid-container-product{
        grid-template-columns: repeat(2, 1fr);
    }

}

@media (max-width: 792px) {
    .grid-container-product, .grid-container-latest{
        grid-template-columns: repeat(1, 1fr);
    }
    #container-product {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
    }


    .card-product-grid img {
        width: 90px;
    }

}


@media (max-width: 792px) {
    .container-car-product {
       max-width: 100%;
    }


}


