#sylius-addresses {
    button,a {
        font-size: 14px;
    }

    .btn-remove-address {
        button{
            font-size: 21px !important;
        }
        position: absolute;
        top: 0;
        right: 0;
    }
}

#account-orders{
    a.btn{
        font-size: 14px;
        padding: 6px 8px;
    }
}

#order-invoices{
    table{
        th:last-child{
            text-align: end;
        }
        td:last-child{
            text-align: end;
            a{
                &:hover{
                   margin-left: 0 !important;
                }
            }
        }
    }

}

.btn-pdf-dwnload {
    display: inline-block;
    line-height: 1;
    vertical-align: baseline;
    margin: 0em 0.14285714em;
    background-color: #99CC33;
    background-image: none;
    padding: 0.5833em 0.833em;
    color: #ffffff;
    text-transform: none;
    font-weight: bold;
    border: 0px solid transparent;

}

.btn-pdf-dwnload:hover {

    background-color: #59771c;


}
