.container-grid {
    margin: 2rem auto;
    display: grid;
    grid-template-rows: 1fr auto 1fr;
    grid-template-columns: 1fr 4fr;
    gap: 8px;
    height: 100%;
    width: 85vw;
    max-width: 1500px;
}

.container-flex{
    margin: 1.5em auto 2.5rem;
    display: flex;
    justify-content: space-around;
    gap: 8px;
    height: 100%;
    width: 85vw;
    max-width: 1600px;
}
.container-flex2 {
    margin: 1.5em auto 2.5rem;
display: flex;
justify-content: space-around;
gap: 8px;
height: 100%;
width: 85vw;
max-width: 1350px;
}

/* --------@ MEDIA query 792PX -------- */
@media (max-width: 1030px) {
    .container-flex{

        justify-content: center;
    }
}
/* --------@ MEDIA query 792PX -------- */
@media (max-width: 792px) {


    .banner {
    margin-top: 2.85rem;
    }
    .container-grid {
        display: flex;
        flex-direction: column ;
        width: 100vw;
    }

    .container-flex{
        width: 100%;
        padding: 0 1rem;
        flex-direction: column;
    }
}

@import "./banner/_sidebar.scss";
@import "./banner/_products-grid.scss";
@import "./banner/_infos.scss";
