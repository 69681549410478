.top-banner {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0.3rem 4rem ;
    font-size: 0.85rem;
    background-color: rgba(0,0,0, 0.8);
    color: $text-color;
}
.picto-paiement {
    width: 80px;
}

/* --------@ MEDIA query 792PX -------- */
@media (max-width: 792px) {
    .top-banner {
        display: none;
    }
}
