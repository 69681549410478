.product-teaser{
    border-radius: 0;
    border: none;
    height: 100%;

    a {
        color: #333;

        .overlay {
            opacity: 0;
            width: 100%;
            height: 350px;
            position: absolute;
            z-index: 600;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .overlay span {
            color: #000;
            font-weight: 700;
            text-align: center;
            font-size: 20px;
            text-transform: uppercase;
        }

        &.btn-product-info {
            width: 100%;
            display: flex;
            justify-content: center;
            font-size: 17px;;
            color: #fff;
            font-weight: 600;
            background: $primary;
            padding: 10px 25px;
        }

        &.image:hover .overlay {
            opacity: 1;
        }

        &.image:hover img {
            filter: opacity(0.3);
        }

    }

    img {
        position: relative;
        z-index: 500;
        margin: 0 auto;
        display: flex;
        width: 100%;
        height: 280px;
        object-fit: cover;
    }

    .card-body {
        display: flex;
        flex-direction: column;
        padding: 0 0 10px;
        justify-content: space-between;

        .card-title {
            text-transform: uppercase;
            font-size: 17px;
            font-weight: 600;
        }
        .short-description{
            font-size: 14px;
        }

        .sylius-product-original-price{
            display: flex;
            justify-content: flex-end;

        }
        .sylius-product-price {
            display: flex;
            justify-content: flex-end;
            font-size: 30px;
            font-weight: 600;
            font-family: "oswald";
            color: #444 ;
            span.price-from-label{
                align-self: end;
                font-size: 12px;
                margin-right: 0.5rem;
                padding-bottom: 2px;
            }
        }
    }
    .card-footer{
        border-radius: 0;
        border: none;
        background: white;
        padding: 0;
        margin: 0;
        .btn-product-info{
            width: 100%;
        }
    }
}

