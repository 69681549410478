
#page-mentions {
    padding: 50px;
    background: #fff;
    color: #000;

    .space {
        margin-top: 15px
    }

    a {
        color: $primary;
        font-weight: 700
    }

    p {
        font-size: 15px;
        margin: 10px 0
    }

    .bloc {
        margin: 20px 0
    }

    .bloc .title {
        display: block;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 20px;
        text-decoration: underline
    }

    .bloc .subtitle {
        display: block;
        font-weight: 700;
        color: #333;
        margin-bottom: 10px;
    }

    .infos {
        margin: 20px 0 0 50px;
    }

    .infos .list {
        display: flex;
        flex-direction: column
    }

    ul.liste {
        list-style: none;
        margin-left: 30px;
    }

    @media screen and (max-width: 990px) {
        .infos {
            margin-left: 0;
        }
        ul.liste {
            margin-left: 0;
        }
        .bloc .title::before {
            width: 400px;
        }
    }
    @media screen and (max-width: 720px) {
        padding: 50px 20px;
        .bloc .title::before {
            width: 250px;
        }
    }

}

