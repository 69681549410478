
.top-head {
    background: #f6f6f6;
    padding: 12px 0;
    width: 100%;

    .cgv, .faq {
        color: #777;
        font-size: 14px;
        font-weight: 700;
    }

    .faq {
        padding-left: 10px;

        &:before {
            content: '|';
            padding-right: 20px;
            @media (max-width: 990px) {
                display: none;
            }
        }
    }

    .shipping-cost {
        display: flex;
        justify-content: flex-end;
        color: $primary;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        @media (max-width: 990px) {
            justify-content: center;
        }
    }
}


header .head {
    @media (min-width: 1200px) {.container { max-width: 1300px;}}

    padding: 12px 0;

    h1 {
        @media (max-width: 990px) {
            text-align: center;
        }
    }

    .logo {
        width: 200px;
        display: flex;
        margin: 0 auto;
    }

    #nav-security {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .nav-item {
            &:not(:last-of-type) {

                margin-right: 1rem;
            }
        }

        @media (min-width: 768px) {
            flex-direction: column;
            justify-content: end;
            .nav-item {
                text-align: end;
            }
        }
        @media (min-width: 1200px) {
            flex-direction: row;
        }
    }

    //.connexion-link {
    //    display: flex;
    //    justify-content: flex-end;
    //    align-items: flex-end;
    //    @media (max-width: 990px) {
    //        justify-content: center;
    //    }
    //
    //    a {
    //        padding: 0 15px;
    //        color: #333;
    //        font-weight: 700;
    //    }
    //
    //    .fa-house {
    //        font-size: 20px;
    //    }
    //
    //    .fa-basket-shopping {
    //        font-size: 32px;
    //        color: #333
    //    }
    //
    //    .cart {
    //        display: flex;
    //        flex-direction: row;
    //        align-items: flex-end;
    //
    //        .quantity {
    //            background: $primary;
    //            height: 26px;
    //            width: 26px;
    //            border-radius: 50%;
    //            display: flex;
    //            justify-content: center;
    //            align-items: center;
    //            margin-left: 5px
    //        }
    //    }
    //}

    #mainNav {
        padding: 0;

        .navbar-nav {
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;

            align-items: center;

            @media (min-width: 990px) {
                justify-content: start;
            }


            .nav-item {
                max-width: 150px;

                &:not(:last-of-type) {
                    @media (min-width: 990px) {
                        margin-right: 2rem;
                    }
                }

                .nav-link {
                    padding: 0;
                    color: #333;
                    text-transform: uppercase;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 700;

                    @media (min-width: 990px) and (max-width: 1200px) {
                        font-size: 12px;
                    }

                    @media (max-width: 990px) {
                        margin-bottom: 20px;
                    }
                }
            }
        }

    }
}
