@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?9znul');
  src:  url('../fonts/icomoon.eot?9znul#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?9znul') format('truetype'),
    url('../fonts/icomoon.woff?9znul') format('woff'),
    url('../fonts/icomoon.svg?9znul#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-calendar:before {
  content: "\e90f";
}
.icon-food:before {
  content: "\e910";
}
.icon-waist:before {
  content: "\e911";
}
.icon-plan:before {
  content: "\e912";
}
.icon-down-arrow:before {
  content: "\e90b";
}
.icon-location-pin:before {
  content: "\e90c";
}
.icon-magnifier:before {
  content: "\e90d";
}
.icon-shopping-cart:before {
  content: "\e90e";
}
.icon-user:before {
  content: "\e906";
}
.icon-edit:before {
  content: "\e907";
}
.icon-delete:before {
  content: "\e908";
}
.icon-plus:before {
  content: "\e909";
}
.icon-minus:before {
  content: "\e90a";
}
.icon-credit-card:before {
  content: "\e900";
}
.icon-delivery:before {
  content: "\e901";
}
.icon-euro:before {
  content: "\e902";
}
.icon-home:before {
  content: "\e903";
}
.icon-basket:before {
  content: "\e904";
}
.icon-truck:before {
  content: "\e905";
}
