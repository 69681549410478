
#container-categorie {
    grid-row-start: 1;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 2;
    max-width: 290px;
    max-height: 1064px;

}

#container-categorie img {
    width: 100%;
    height: auto;
    margin: 0.5rem 0rem;
    filter: brightness(0.99);
    transition: filter 0.3s ease;
}
#container-categorie img:hover {
    filter: brightness(1.2);
}


.menu-container-categorie {
    padding: 10px 15px;
    border: #B4AF9D solid 1px;
    margin: 0.5rem 0rem;
}
.menu-container-categorie a, .menu-container-categorie ul, .menu-container-categorie li{
    text-decoration: none;
    color: #b4af9d;
    font-size: 0.8rem;
    font-weight: 400;
    transition: all 0.3s ease;
    line-height: 1.5rem;
}
.menu-container-categorie a:hover{
    font-size: 0.81rem;
    color: #7e7a6c;

}

.menu-container-categorie ul {
    list-style-type: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}
.container-img-sidebar {
    position:relative;
}
.container-img-sidebar:hover img,
.container-img-sidebar:hover span {
    filter: brightness(1.1)!important;
}

    .container-img-sidebar span {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    color: #eee;
    font-weight: bolder;
    text-transform: uppercase;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: calc(0.1rem + 0.85vw);
}

.expandable-button {
    cursor: pointer;
}

.expandable-content.open {
    max-height: 360px;
    opacity: 1;
}
.expandable-content {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    list-style: none;
    margin: 0;
    padding: 0;
}
.expandable-content li a{
    color : #000;
    font-weight : 400;
    font-size : 0.8rem;
    transition: all 0.3s ease;
}
@media (max-width: 1030px) {
    .side-bar-details {
        display:none;
        }
    }

@media (max-width: 792px) {
#container-categorie {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    }

.container-img-sidebar span{
    font-size:1.2rem;
    }
}





