.banner {
    background-color: #4a4a54;
    background-image: url("../../../media/images/header2.jpg");
    background-repeat: no-repeat;
    background-position: 50% 0%;
    width: 100%;
    height: 220px;
}

#slug-home-header {
    display: block;
    width: 70%;
    height: 100%;
    margin: auto;
}

#message-info-subheader {
    border:solid 1px #B4AF9D;
    background-color:#fff;
    margin:1rem auto 0;
    padding:1rem 2rem ;

}
